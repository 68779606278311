
<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple">
            <div class="title_h">{{ $t("message.add_region") }}</div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <!-- <el-button type="primary">Сохранить</el-button> -->
              <el-button
                type="success"
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submit(true)"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="close()">{{ $t("message.close") }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="center__block_form">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        class="aticler_m"
        size="small"
      >
        <el-row :gutter="25">
          <el-col :span="12">
            <el-form-item :label="$t('message.city')">
              <cities v-model="form.city_id" :city_id="form.city_id"></cities>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('message.region')" prop="name">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('message.code')">
              <el-input v-model="form.code"></el-input>
            </el-form-item>
          </el-col>
          <!-- end col -->
        </el-row>
        <!-- end el-row -->
      </el-form>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import form from "@/utils/mixins/form";
import cities from "@/components/selects/city";
import drawerChild from "@/utils/mixins/drawer-child";
export default {
  mixins: [form, drawerChild],
  components: {
    cities,
  },
  data() {
    return {};
  },
  created() {},
  computed: {
    ...mapGetters({
      rules: "regions/rules",
      model: "regions/model",
      columns: "regions/columns",
    }),
  },
  methods: {
    ...mapActions({
      save: "regions/store",
      empty: "regions/empty",
    }),
    afterOpened() {
      this.form = JSON.parse(JSON.stringify(this.model));
    },
    afterClosed() {
      this.empty();
    },
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$alert(res);
              this.parent().listChanged();
              if (close == true) {
                this.close();
              }
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
  },
};
</script>
